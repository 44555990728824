import React, { useState } from 'react';
import Sidebar from '../components/Sidebar'; // 경로 확인
import VideoList from '../components/VideoList'; // 경로 확인
import '../styles/Search.css';

function Search() {
  const [selectedCategory, setCategory] = useState('테마 전체');
  const [selectedRegion, setRegion] = useState('지역 전체');

  const resetSidebar = () => {
    setCategory('테마 전체');
    setRegion('지역 전체');
  };

  return (
    <div className="search">
      <Sidebar
        setRegion={setRegion}
        setCategory={setCategory}
        selectedRegion={selectedRegion}
        selectedCategory={selectedCategory}
        resetSidebar={resetSidebar}
      />
      <VideoList region={selectedRegion} category={selectedCategory} />
    </div>
  );
}

export default Search;
