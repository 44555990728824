import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import '../styles/NavbarWithLogin.css';

function NavbarWithLogin({ videos = [] }) {
  const [isLoginBarVisible, setLoginBarVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    if (searchQuery.trim() && videos.length > 0) {
      const results = videos.filter((video) =>
        video.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVideos(results);
    } else {
      setFilteredVideos([]);
    }
  }, [searchQuery, videos]);

  const getYouTubeThumbnail = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleVideoClick = (id) => {
    setSearchQuery('');
    navigate(`/video/${id}`);
  };

  return (
    <>
      <nav className="nav">
        <h1 className="nav-logo" onClick={() => navigate('/')}>
          MyWebsite
        </h1>
        <div className="nav-menu">
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/regions" className="nav-link">지역별</Link>
          <Link to="/themes" className="nav-link">테마별</Link>
          <Link to="/board" className="nav-link">게시판</Link>
        </div>
        <div className="nav-search-container">
          <input
            className="nav-search-bar"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          {filteredVideos.length > 0 && (
            <div className="search-results">
              {filteredVideos.map((video) => (
                <div
                  key={video.id}
                  className="search-result-item"
                  onClick={() => handleVideoClick(video.id)}
                >
                  <img
                    src={getYouTubeThumbnail(video.url)}
                    alt={video.title}
                    className="search-thumbnail"
                  />
                  <span className="search-title">{video.title}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="nav-user-icon">
          {user ? (
            <div className="nav-user-info">
              <span>{`안녕하세요, ${user.name}!`}</span>
              <button className="logout-btn" onClick={() => {
                localStorage.removeItem('user');
                setUser(null);
                navigate('/');
              }}>로그아웃</button>
            </div>
          ) : (
            <FaUserCircle size={28} onClick={() => setLoginBarVisible(!isLoginBarVisible)} />
          )}
        </div>
      </nav>
      {isLoginBarVisible && <LoginBar onClose={() => setLoginBarVisible(false)} setUser={setUser} />}
    </>
  );
}
function LoginBar({ onClose, setUser }) {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://port-0-testback-m48l4071e2fcab85.sel4.cloudtype.app/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, password }),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        alert(data.message);
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
        onClose();
        navigate('/');
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('로그인 오류:', error);
      setErrorMessage('서버 오류가 발생했습니다.');
    }
  };
  
  const handleSignUpRedirect = () => {
    onClose();
    navigate('/signup');
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Login</h2>
        <button className="sidebar-close-btn" onClick={onClose}>✕</button>
      </div>
      <form className="sidebar-form" onSubmit={handleLogin}>
        <input
          className="sidebar-input"
          type="text"
          placeholder="아이디"
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
        />
        <input
          className="sidebar-input"
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="sidebar-btn-container">
          <button className="sidebar-btn" type="submit">로그인</button>
          <button className="sidebar-btn signup-btn" onClick={handleSignUpRedirect}>
            회원가입
          </button>
        </div>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default NavbarWithLogin;
