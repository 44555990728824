import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/AddBoard.css';
import axios from "axios";

function AddBoard() {
    const [newPost, setNewPost] = useState({ title: '', content: '', category: '' });
    const navigate = useNavigate();
    const handleCategoryChange = (e) => {
        setNewPost({ ...newPost, category: e.target.value });
    };
    
    const [boardPosts, setBoardPosts] = useState([]);

    // 로그인 했는지 확인
    const loginUser = async (username, password) => {
        try {
          const response = await axios.post('https://port-0-testback-m48l4071e2fcab85.sel4.cloudtype.app/login', {
              username,
              password,
          });
          if (response.data.status === 1) {
              const userId = response.data.data.user_id; // 서버에서 반환한 user_id
              localStorage.setItem('user_id', userId); // localStorage에 저장
              
          }
      } catch (error) {
          console.error('로그인 중 오류 발생:', error);
      }
    };

    // 현재 로그인된 사용자 ID 가져오기
    const userId = localStorage.getItem('user_id');

    const addBoardPost = async () => {
        if (newPost.title && newPost.content && newPost.category) {
            try {
                const response = await axios.post('https://museon.leenux.kr/post/create.php', {
                        title: newPost.title,
                        content: newPost.content,
                        user_id: userId,
                        category: newPost.category,
                });
                console.log(response);

                if (response.data.status === 1) {
                    alert('게시글이 추가되었습니다!');
                    console.log(response);
                    navigate('/freeboard'); // 추가 후 메인 페이지로 이동
                    
                } else {
                    alert('게시글 추가에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error adding post:', error);
            }
        } else {
            alert('제목, 내용, 그리고 카테고리를 모두 입력하세요!');
        }
    };

    return (
        <div className="add-post">
            <div className="input-container">
                {/* 카테고리 선택 */}
            <div className="category-container">
                <label htmlFor="category"></label>
                <select id="category" value={newPost.category} onChange={handleCategoryChange}>
                    <option value=""> ▼ 카테고리 선택 ▼ </option>
                    <option value="자유">자유</option>
                    <option value="추천">추천</option>
                    <option value="뮤지컬">뮤지컬</option>
                    <option value="연극">연극</option>
                </select>
            </div>

                <input
                    type="text"
                    placeholder="제목"
                    value={newPost.title}
                    onChange={(e) => setNewPost({ ...newPost, title: e.target.value })}
                />
                <textarea
                    placeholder="내용"
                    value={newPost.content}
                    onChange={(e) => setNewPost({ ...newPost, content: e.target.value })}
                ></textarea>
                <button onClick={addBoardPost}>추가</button>
                <button onClick={() => navigate('/')}>취소</button>
            </div>
        </div>
    );
}

export default AddBoard;
