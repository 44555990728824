import React, { useState, useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import AddBoard from './AddBoard';
import '../styles/Freeboard.css';

function Musicalboard() {
    const [boardPosts, setBoardPosts] = useState([]); // 전체 게시글 목록
    const [filteredPosts, setFilteredPosts] = useState([]); // 선택된 카테고리의 게시글
    const [selectedCategory, setSelectedCategory] = useState('뮤지컬'); // 기본 선택된 카테고리
    const [selectedPost, setSelectedPost] = useState(null); // 선택된 게시글

    // 게시글 목록을 API에서 가져오기
    const fetchBoardPosts = async () => {
        try {
            const response = await fetch('https://museon.leenux.kr/post/list.php');
            if (!response.ok) {
                throw new Error('API 요청 실패');
            }
            const data = await response.json();
            const posts = Array.isArray(data.data) ? data.data : [];
            const formattedPosts = posts.map((item) => ({
                title: item.title || '제목 없음',
                category: item.category || '카테고리 없음',
                authorId: item.user_id || '작성자 미상',
                content: item.content || '내용 없음',
            }));
            setBoardPosts(formattedPosts);
            setFilteredPosts(formattedPosts.filter(post => post.category === '뮤지컬'));
        } catch (error) {
            console.error('데이터 가져오기 실패:', error);
        }
    };

    useEffect(() => {
        fetchBoardPosts();
    }, []);

    useEffect(() => {
        const filtered = boardPosts.filter(post => post.category === selectedCategory);
        setFilteredPosts(filtered);
    }, [boardPosts, selectedCategory]);

    const handlePostClick = (post) => {
        setSelectedPost(selectedPost && selectedPost.title === post.title ? null : post);
    };

    return (
        <div className="fbApp">
            <h1>뮤지컬게시판</h1>
            <div className="input-container">
                <Link to="/board/addboard">
                    <button>글쓰기</button>
                </Link>
                <Routes>
                    <Route path="/addboard" element={<AddBoard />} />
                </Routes>
                <br />

                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    <option value="뮤지컬">뮤지컬</option>
                </select>
            </div>

            <br/><br/>
            <table className="post-table">
                <thead>
                    <tr>
                        <th>제목</th>
                        <th>카테고리</th>
                        <th>작성자</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPosts.map((post, index) => (
                        <tr key={index} onClick={() => handlePostClick(post)} style={{ cursor: 'pointer' }}>
                            <td>{post.title}</td>
                            <td>{post.category}</td>
                            <td>{post.authorId}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedPost && (
                <div className="post-content">
                    <h3>내용</h3>
                    <p>{selectedPost.content}</p>
                </div>
            )}
        </div>
    );
}

export default Musicalboard;
