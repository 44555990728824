import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';

function Home() {
  const [videos, setVideos] = useState([]);
  const [recommendedVideo, setRecommendedVideo] = useState(null); // 추천 비디오 상태 추가
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://museon.leenux.kr/musical/list.php');
        const data = await response.json();
        if (data.status === 1 && Array.isArray(data.data)) {
          setVideos(data.data);

          // 랜덤으로 추천 비디오 설정
          const randomIndex = Math.floor(Math.random() * data.data.length);
          setRecommendedVideo(data.data[randomIndex]);
        } else {
          console.error('Invalid data format:', data);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  const getYouTubeThumbnail = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const handleVideoClick = (id) => {
    navigate(`/video/${id}`);
  };

  const otherVideos = videos.filter((video) => video !== recommendedVideo); // 추천 비디오 제외

  return (
    <div className="home-container">
      {recommendedVideo && (
        <div
          className="home-recommended"
          onClick={() => handleVideoClick(recommendedVideo.id)}
          style={{
            backgroundImage: `url(${getYouTubeThumbnail(recommendedVideo.url)})`,
          }}
        >
          <div className="home-recommended-overlay">
            <h2 className="home-recommended-title">{recommendedVideo.title}</h2>
            <button
              className="home-watch-now-button"
              onClick={() => handleVideoClick(recommendedVideo.id)}
            >
              Watch Now
            </button>
          </div>
        </div>
      )}

      <h1>뮤지컬 목록</h1>
      <div className="home-movies-grid">
        {otherVideos.map((video) => (
          <div
            key={video.id}
            className="home-movie-card"
            onClick={() => handleVideoClick(video.id)}
          >
            <img
              src={video.url.includes('youtube.com') ? getYouTubeThumbnail(video.url) : `${video.url}#t=1`}
              alt={video.title}
              className="home-thumbnail-image"
            />
            <div className="home-thumbnail-title">{video.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
