export const videos = [
    { id: 1, title: '뮤지컬1', region: '지역1', category : '뮤지컬', date:'2019-04-25' },
    { id: 2, title: '연극1', region: '지역2', category : '연극', date:'2017-08-21' },
    { id: 3, title: '연극2', region: '지역3', category : '연극', date:'2016-03-27' },
    { id: 4, title: '뮤지컬2', region: '지역3', category : '뮤지컬', date:'2019-11-15' },
    { id: 5, title: '뮤지컬3', region: '지역4', category : '뮤지컬', date:'2016-08-30' }
  ];
  
  export const getRegions = () => {
    return ['지역 전체', ...new Set(videos.map(video => video.region))];
  };

  export const getCategories = () => {
    return ['테마 전체', ...new Set(videos.map(video => video.category))];
  };