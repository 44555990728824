import React from 'react';
import VideoCard from './VideoCard';
import { videos } from '../data';

const VideoList = ({ category, region }) => {

  const filteredVideos = videos.filter((video) => 
    (category === '테마 전체' || video.category === category) &&
    (region === '지역 전체' || video.region === region)
  );

  return (
    <div>
      <h5>총 {filteredVideos.length}개의 영상이 검색되었습니다.</h5>

    <div className="video-list">
      {filteredVideos.length > 0 ? (
        filteredVideos.map((video) => (
          <VideoCard key={video.id} video={video} />
        ))
      ) : <p>영상을 추가중입니다.</p>
    }
    </div>
    </div>
  );
};

export default VideoList;
