import React from 'react';
import { getCategories, getRegions } from '../data';
import '../styles/Sidebar.css';

const Sidebar = ({ 
  setRegion, 
  setCategory, 
  selectedRegion, 
  selectedCategory, 
  resetSidebar 
}) => {
  const categories = getCategories();
  const regions = getRegions();

  return (
    <div className="custom-sidebar">
      <h2>카테고리</h2>

      <div className="resetSet">
        <button className="reset" onClick={resetSidebar}>
          초기화
        </button>
      </div>
      
      <div className="sidebarSet">
        <h4>테마</h4>
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setCategory(category)}
            className={category === selectedCategory ? 'selectd' : ''}
          >
            {category}
          </button>
        ))}

        <h4>지역</h4>
        {regions.map((region) => (
          <button
            key={region}
            onClick={() => setRegion(region)}
            className={region === selectedRegion ? 'selectd' : ''}
          >
            {region}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
