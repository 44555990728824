import { Routes, Route, Link } from 'react-router-dom';
import Freeboard from '../components/Freeboard';
import Recoboard from '../components/Recoboard';
import Musicalboard from '../components/Musicalboard';
import Playboard from '../components/Playboard';
import AddBoard from '../components/AddBoard';
import '../styles/BoardMain.css';

function BoardMain() {
  return (
    <div className="board-main">
      {/* Navigation */}
      <header className="board-header">
        <h1 className="logo">Logo</h1>
        <nav className="nav-menu">
          <Link to="/" className="nav-item">홈</Link>
          <Link to="/musicalboard" className="nav-item">뮤지컬</Link>
          <Link to="/playboard" className="nav-item">연극</Link>
          <Link to="/freeboard" className="nav-item">게시판</Link>
        </nav>
        
      </header>

      {/* Board Buttons */}
      <div className="board-buttons">
        <Link to="/freeboard">
          <button className="board-btn">자유게시판</button>
        </Link>
        <Link to="/recoboard">
          <button className="board-btn">추천게시판</button>
        </Link>
        <Link to="/musicalboard">
          <button className="board-btn">뮤지컬게시판</button>
        </Link>
        <Link to="/playboard">
          <button className="board-btn">연극게시판</button>
        </Link>
      </div>

      {/* Routes */}
      <main className="board-content">
        <Routes>
          <Route path="/addboard" element={<AddBoard />} />
          <Route path="/freeboard" element={<Freeboard />} />
          <Route path="/recoboard" element={<Recoboard />} />
          <Route path="/musicalboard" element={<Musicalboard />} />
          <Route path="/playboard" element={<Playboard />} />
        </Routes>
      </main>
    </div>
  );
}

export default BoardMain;
