import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/VideoDetail.css';

function VideoDetail() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch('https://museon.leenux.kr/musical/list.php');
        const result = await response.json();
        if (result.status === 1 && Array.isArray(result.data)) {
          const selectedVideo = result.data.find((v) => v.id.toString() === id);
          setVideo(selectedVideo);
        } else {
          console.error('Invalid data format:', result);
        }
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchVideo();
  }, [id]);

  if (!video) return <p>Loading...</p>;

  const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const isYouTubeUrl = video.url.includes('youtube.com');

  return (
    <div className="video-detail">
      <h1>{video.title}</h1>
      {isYouTubeUrl ? (
        <iframe
          width="100%"
          height="500px"
          src={getYouTubeEmbedUrl(video.url)}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={video.title}
        ></iframe>
      ) : (
        <video controls width="100%">
          <source src={video.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <p><strong>줄거리:</strong> {video.plot}</p>
      <p><strong>출연:</strong> {video.cast}</p>
    </div>
  );
}

export default VideoDetail;
