import React from 'react';

const VideoCard = ({ video }) => {
  return (
    <div className="video-card">

      <img 
      alt={video.title} 
      className="video-thumbnail"
      />

      <div className="video-info">

        <h3>{video.title}</h3>
        <p>{video.region} / {video.category}</p>
        <p>{video.date}</p>

      </div>
    </div>
  );
};

export default VideoCard;
