import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarWithLogin from './components/NavbarWithLogin';
import Home from './page/Home';
import Search from './page/Search';
import BoardMain from './page/BoardMain';
import Signup from './components/Signup';
import VideoDetail from './page/VideoDetail';
import Freeboard from './components/Freeboard';
import Playboard from './components/Playboard';
import Recoboard from './components/Recoboard';
import AddBoard from './components/AddBoard';
import Musicalboard from './components/Musicalboard';
function App() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://museon.leenux.kr/musical/list.php');
        const data = await response.json();
        if (data.status === 1 && Array.isArray(data.data)) {
          setVideos(data.data); // API에서 가져온 데이터를 상태로 설정
        } else {
          console.error('Invalid data format:', data);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <Router>
      <NavbarWithLogin videos={videos} />
      <Routes>
        <Route path="/" element={<Home videos={videos} />} />
        <Route path="/regions" element={<Search />} />
        <Route path="/themes" element={<Search />} />
        <Route path="/board/" element={<BoardMain />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/video/:id" element={<VideoDetail videos={videos} />} />
        <Route path="/freeboard" element={<Freeboard />} />
        <Route path="/playboard" element={<Playboard />} />
        <Route path="/recoboard" element={<Recoboard />} />
        <Route path="/board/addboard" element={<AddBoard />} />
        <Route path="/Musicalboard" element={<Musicalboard />} />
      </Routes>
    </Router>
  );
}

export default App;
