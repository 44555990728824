import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Signup.css';

function Signup() {
  const [formData, setFormData] = useState({
    id: '', // id로 수정
    password: '',
    name: '',
    age: '',
    phone: '',
  });

  const [message, setMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('https://port-0-testback-m48l4071e2fcab85.sel4.cloudtype.app/signup', formData);
      setMessage(response.data.message);
      setAlertType('success');
      setTimeout(() => {
        navigate('/'); // 성공 시 2초 후 홈으로 이동
      }, 2000);
    } catch (error) {
      console.error('회원가입 오류:', error);
      setMessage(error.response?.data?.message || '회원가입 실패');
      setAlertType('error');
    }
  };

  return (
    <div className="signup-container">
      <h1>회원가입</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>아이디</label>
          <input
            type="text"
            name="id" // name을 id로 수정
            value={formData.id}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>비밀번호</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>이름</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>나이</label>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>전화번호</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">회원가입</button>
      </form>
      {message && (
        <div className={`alert ${alertType}`}>
          {message}
        </div>
      )}
    </div>
  );
}

export default Signup;
